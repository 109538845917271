/** @format */

import React from "react";
import "./AvailableRooms.css";
import { Link } from "react-router-dom";
const AvailableRooms = ({ amount, index, room, roomId }) => {
  return (
    // <button key={index} className='availableRooms'>{items}</button>
    <Link
      to={{
        pathname: `/AssignRoom`,
        search: `?amount=${amount}&room=${room}&roomId=${roomId}`,
      }}
     className="availableRooms">
      <button key={index} className="availableRooms-dashboard-button">
        <h3>{amount}</h3> <br />
        <span>{room}</span>
      </button>
    </Link>
  );
};

export default AvailableRooms;
