import React from "react";
import './BartenderManageGuestList.css'
import { Link } from "react-router-dom";
import copy from "../../../../assets/Copy.svg";

const BartenderManageGuestList = ({index, Position, Id, Name}) => {
  return (
    <div className="BartenderManageGuestList-table-row" key={index}>
      <Link className="BartenderManageGuestListItem-link">{Name}</Link>
      <div className="BartenderManageGuestListItem-id">
        <p>{Id}</p>{" "}
        <button className="BartenderManageGuestListItem-id-btn">
          <img src={copy} alt="" />
        </button>
      </div>
      <p className="BartenderManageGuestListItem-position">{Position}</p>
    </div>
  );
};

export default BartenderManageGuestList;
