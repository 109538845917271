import React, { useState } from "react";
import "./AdminAccounts.css";
import AdminProfile from "../AdminProfile/AdminProfile";
import AdminAccountList from "./AdminAccountList/AdminAccountList";
import AdminHeader from "../AdminHeader/AdminHeader";
import { useGetPaymentsQuery } from "../../../hooks/payment";
import { useGetStaffsQuery } from "../../../hooks/staff";
import { formatDistanceToNow } from "date-fns";
import { CgSpinner } from "react-icons/cg";

const AdminAccounts = () => {
  const { data: AdminAccountitems } = useGetPaymentsQuery();
  const { data: staff } = useGetStaffsQuery();

  const [adminProfileShow, setAdminProfileShow] = useState(false);

  return (
    <div className="AdminAccounts">
      <div className="AdminAccounts-profile">
        <AdminProfile adminProfileShow={true} />
      </div>
      <div className="AdminAccounts-space">
        <div className="AdminAccounts-mobile-header">
          <AdminProfile
            adminProfileShow={adminProfileShow}
            setAdminProfileShow={setAdminProfileShow}
          />
          <AdminHeader setAdminProfileShow={setAdminProfileShow} />
        </div>
        <div className="AdminAccounts-space-head">
          <p>Back to Homepage</p>
        </div>
        <div className="AdminAccounts-space-body">
          <div className="AdminAccounts-title-board">
            <h2>Income:</h2>
            <input type="text" placeholder="Search Staff" />
          </div>
          <section className="AdminAccounts-board">
            <div className="AdminAccounts-center-board">
              <div className="AdminAccounts-table-head">
                <p>Staff Name</p>
                <p>Category</p>
                <p>Bill</p>
                <p>Paid</p>
                <p>Balance</p>
                <p>Date</p>
              </div>
              <div className="AdminAccounts-table-body">
                {AdminAccountitems?.map((item, index) => {
                  const staffMember = staff?.find(
                    (s) => s.staffUniqueId === item.collectedBy
                  );

                  const balance = item.bill - item.paid; // Calculate balance
                  const formattedDate = formatDistanceToNow(
                    new Date(item.createdAt),
                    {
                      addSuffix: true,
                    }
                  ); // Format the createdAt date

                  return (
                    <AdminAccountList
                      key={index}
                      name={
                        staffMember ? (
                          staffMember.fullName
                        ) : (
                          <CgSpinner
                            className="animate-spin"
                            color="black"
                            size={20}
                          />
                        )
                      }
                      category={item.category}
                      bill={item.bill}
                      paid={item.paid}
                      balance={`${balance}.00`}
                      date={formattedDate}
                      index={index}
                    />
                  );
                })}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AdminAccounts;
