import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetGuestDetailsQuery } from "../../../hooks/guest";
import {
  useFetchPaymentsByUserId,
  useMakePaymentMutation,
} from "../../../hooks/payment";
import { useGetRoomDetailsQuery } from "../../../hooks/room";
import { Store } from "../../../Store";
import GuestReservationHeader from "../../GuestReservationHeader/GuestReservationHeader";
import "./UpdateGuestPayment.css";

const UpdateGuestPayment = () => {
  const navigate = useNavigate();
  const {
    state: { userInfo },
  } = useContext(Store);

  console.log(userInfo);

  const location = useLocation();
  const [collectedFrom, setCollectedFrom] = useState("");
  const [category, setCategory] = useState("Room");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [comment, setComment] = useState("");
  const [bill, setBill] = useState(""); // Total bill amount
  const [paid, setPaid] = useState(""); // Amount paid
  const [id, setId] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showCommentBox, setShowCommentBox] = useState(false);

  const collectedBy = userInfo.uniqueId;

  const { data: guest } = useGetGuestDetailsQuery(id || "");
  const { data: checkin_rooms } = useGetRoomDetailsQuery(collectedFrom);
  const { data: payment } = useFetchPaymentsByUserId(id);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const uniqueId = queryParams.get("id");
    const index = queryParams.get("questId");

    setId(index);
    setCollectedFrom(uniqueId);
  }, [location.search]);

  useEffect(() => {
    if (category === "Room" && selectedRoom) {
      const selectedRoomData = checkin_rooms?.find(
        (room) => room._id === selectedRoom
      );
      // Check if the selected room already has a payment
      const existingPayment = payment?.find((pay) =>
        pay.comment.includes(`id:${selectedRoom}`)
      );
      if (existingPayment) {
        setBill(existingPayment.bill - existingPayment.paid);
        setSelectedItemId(existingPayment._id); // Save the payment ID for updates
      } else if (selectedRoomData) {
        setBill(selectedRoomData.price);
        setSelectedItemId(null);
      }
    } else if (selectedItemId) {
      const existingPayment = payment?.find(
        (item) => item._id === selectedItemId
      );
      if (existingPayment) {
        setBill(existingPayment.bill - existingPayment.paid);
      }
    } else {
      setBill("");
    }
  }, [category, selectedRoom, selectedItemId, checkin_rooms, payment]);

  const { mutateAsync: savePayment } = useMakePaymentMutation();

  const handleRoomSelection = (e) => {
    const roomId = e.target.value;
    setSelectedRoom(roomId);

    const selectedRoomData = checkin_rooms?.find((room) => room._id === roomId);
    if (selectedRoomData) {
      setBill(selectedRoomData.price);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const paidNumber = Number(paid);
    const billNumber = Number(bill);

    if (paidNumber > billNumber) {
      toast.error("Amount paid cannot be greater than the bill.");
      return;
    }

    let finalComment = comment;
    if (category === "Room") {
      const selectedRoomData = checkin_rooms?.find(
        (room) => room._id === selectedRoom
      );
      finalComment = selectedRoomData
        ? `[name:${selectedRoomData.name},id:${selectedRoomData._id},createdAt:${selectedRoomData.createdAt}]`
        : "";
    }

    const existingPayment = payment?.find(
      (item) => item.category === category && item.selectedRoom === selectedRoom
    );
    const totalPaid = existingPayment
      ? Number(existingPayment.paid) + paidNumber
      : paidNumber;

    try {
      const paymentData = {
        collectedFrom,
        collectedBy,
        userid: id,
        staffid: userInfo._id,
        category,
        bill: billNumber,
        paid: totalPaid,
        comment: finalComment,
        selectedRoom,
        catid: selectedItemId, // Send the category ID
      };

      await savePayment(paymentData);
      toast.success("Payment updated successfully");
      navigate(`/GuestDashBoardRoom?id=${collectedFrom}&&index=${id}`);
    } catch (error) {
      console.error("Error updating payment:", error);
      toast.error(error.message || "An unexpected error occurred");
    }
  };

  const toggleCommentBox = () => {
    setShowCommentBox((prevState) => !prevState);
  };

  return (
    <div className="UpdateGuestPayment">
      <div className="UpdateGuestPayment-hue">
        <div className="UpdateGuestPayment-head">
          <GuestReservationHeader />
        </div>
        <div className="UpdateGuestPayment-body">
          <form
            onSubmit={handleSubmit}
            className="UpdateGuestPayment-form"
            style={{ marginTop: -50 }}
          >
            <div className="UpdateGuestPayment-header-details">
              <p className="UpdateGuestPayment-title">Update Payments</p>
              <h1>{guest?.fullName}</h1>
              <p className="UpdateGuestPayment-staff">
                Collected by: {userInfo ? userInfo.fullName : ""}
              </p>
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                id="paymentCategory"
              >
                <option value="">Select Categoryto update</option>
                <option value="Laundry">Laundry</option>
                {userInfo &&
                  (userInfo.position === "Admin" ||
                    userInfo.position === "Receptionist") && (
                    <option value="Room">Room</option>
                  )}
                <option value="Pool">Pool</option>
                <option value="Restaurant">Restaurant</option>
                <option value="Bar">Bar</option>
                <option value="Others">Others</option>
              </select>
            </div>

            {/* Room Payment Section */}
            {category === "Room" ? (
              <>
                {userInfo &&
                  (userInfo.position === "Admin" ||
                    userInfo.position === "Receptionist") && (
                    <select
                      value={selectedRoom}
                      onChange={handleRoomSelection}
                      className="room-dropdown"
                      required
                    >
                      <option className="room-option">Select Room</option>
                      {checkin_rooms?.map((room) => {
                        const matchingPayment = payment?.find(
                          (pay) =>
                            pay.category === "Room" &&
                            pay.comment.includes(`id:${room._id}`)
                        );

                        return (
                          <option
                            className="room-option"
                            key={room._id}
                            value={room._id}
                          >
                            {room.name} {"  "}
                            {matchingPayment
                              ? `( Bill: N ${matchingPayment.bill}, Paid: N ${matchingPayment.paid} )`
                              : `( N ${room.price} )`}
                          </option>
                        );
                      })}
                    </select>
                  )}

                <div className="existing-payments">
                  {payment
                    ?.filter(
                      (pay) =>
                        pay.category === "Room" &&
                        pay.selectedRoom === selectedRoom
                    )
                    .map((pay, index) => (
                      <div key={index} className="payment-detail">
                        <p>Bill: N {pay.bill}</p>
                        <p>Paid: N {pay.paid}</p>
                        <p>Comment: {pay.comment}</p>
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <>
                {/* Other Payment Categories */}
                {payment?.some((item) => item.category === category) ? (
                  <>
                    <button type="button" onClick={toggleCommentBox}>
                      {showCommentBox
                        ? `Update existing ${category} Items`
                        : `Add new ${category} Items`}
                    </button>

                    {!showCommentBox && (
                      <select
                        value={selectedItemId}
                        onChange={(e) => setSelectedItemId(e.target.value)}
                        className="room-dropdown"
                      >
                        <option value="">{`Select ${category} item to update`}</option>
                        {payment
                          .filter((item) => item.category === category)
                          .map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.comment} {"  "} (Bill: {item.bill}) (Paid:{" "}
                              {item.paid})
                            </option>
                          ))}
                      </select>
                    )}

                    {showCommentBox && (
                      <textarea
                        name="comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        rows={4}
                        cols={50}
                        placeholder={`Add additional ${category} items`}
                      />
                    )}
                  </>
                ) : (
                  <textarea
                    name="comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    rows={4}
                    cols={50}
                    placeholder="Comments"
                  />
                )}
              </>
            )}

            <label>
              Bill:
              <input
                value={bill}
                onChange={(e) => setBill(e.target.value)}
                type="text"
                readOnly={category === "Room"}
              />
            </label>

            <label>
              Paid:
              <input
                type="text"
                value={paid}
                onChange={(e) => setPaid(e.target.value)}
              />
            </label>
            <button type="submit">Update</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateGuestPayment;
