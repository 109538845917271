/** @format */

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import play_btn from "../../../assets/play-icon.svg";

import toast from "react-hot-toast";
import { useGetGuestDetailsQuery } from "../../../hooks/guest";
import {
  useCheckoutPaymentMutation,
  useFetchPaymentsByUserId,
} from "../../../hooks/payment";
import {
  useCheckoutRoomMutation,
  useGetRoomDetailsQuery,
} from "../../../hooks/room";
import { Store } from "../../../Store";
import CashierProfile from "../../Cashier/CashierProfile/CashierProfile";
import ReceptionHeader from "../ReceptionHeader/ReceptionHeader";
import ReceptionistDashboard from "../ReceptionistDashboard/ReceptionistDashboard";
import "./GuestDashBoardRoom.css";
import { DateFormatter } from "../../../utils/DateFOrmatter";
import AdminProfile from "../../Master-Admin/AdminProfile/AdminProfile";

const GuestDashBoardRoom = () => {
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);
  const [identifier, setIdentifier] = useState(null);
  const [index, setIndex] = useState(null);
  const { data } = useGetRoomDetailsQuery(identifier);

  const {
    state: { userInfo },
  } = useContext(Store);

  const { data: guest } = useGetGuestDetailsQuery(index || "");
  const { mutateAsync: updateRoom } = useCheckoutRoomMutation();
  const { mutateAsync: updatePayment } = useCheckoutPaymentMutation();
  const { data: payment } = useFetchPaymentsByUserId(index);

  useEffect(() => {
    const hash = window.location.hash;
    const queryString = hash.split("?")[1];
    const params = new URLSearchParams(queryString);
    const id = params.get("id");
    const index = params.get("index");

    const userUniqueId = params.get("userUniqueId");

    setIndex(index);

    // Set identifier to either `id` or `userUniqueId`
    setIdentifier(id || userUniqueId);
  }, []);

  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);

  const handlePlayButtonClick = () => {
    setIsVisible(!isVisible);
  };
  //  handle checkedout
  const handleCheckout = async (e) => {
    e.preventDefault();

    // Update room availability
    const updateRoomPromises = data
      .filter((room) => !room.isAvailable)
      .map((room) =>
        updateRoom({
          payload: { isAvailable: true, type: "", roomId: room._id },
        })
      );

    // Update payment to mark as checked out
    const updatePaymentPromises = payment
      .filter((pay) => !pay.checkedout)
      .map((pay) => updatePayment(pay._id));

    try {
      // Run both updates in parallel
      await Promise.all([...updateRoomPromises, ...updatePaymentPromises]);
      toast.success("Checkout successful");
    } catch (error) {
      toast.error("Checkout failed");
    }
  };

  return (
    <>
      <div className="GuestDashBoardRoom">
        <div className="GuestDashBoardRoom-web">
          <div className="GuestDashBoardRoom-profile-section">
            {/* <ReceptionistDashboard receptionProfileShow={true} /> */}

            {userInfo && userInfo.position === "Receptionist" ? (
              <ReceptionistDashboard receptionProfileShow={true} />
            ) : userInfo && userInfo.position === "Admin" ? (
              <AdminProfile adminProfileShow={true} />
            ) : (
              <CashierProfile cashProfileShow={true} />
            )}
          </div>

          <div className="GuestDashBoardRoom-content-section">
            <div className="GuestDashBoardRoom-mobile-header">
              <ReceptionistDashboard
                receptionProfileShow={receptionProfileShow}
                setReceptionProfileShow={setReceptionProfileShow}
              />
              <ReceptionHeader
                setReceptionProfileShow={setReceptionProfileShow}
              />
            </div>
            <div className="GuestDashBoardRoom-content-head">
              <Link to="/">Back to Homepage</Link>
            </div>
            <div className="GuestDashBoardRoom-content-body">
              <div className="GuestDashBoardRoom-option-details">
                <div className="GuestDashBoardRoom-user-detail">
                  {/* User details can be added here if needed */}
                  <p>{guest?.fullName || ""}</p>
                  <p>{identifier}</p>
                </div>
                <div className="GuestDashBoardRoom-recep-detail">
                  <Link
                    to={`/UpdateGuestPayment?id=${identifier}&&questId=${index}`}
                  >
                    <button>Payments</button>
                  </Link>
                  <Link to={`/Receipt?id=${identifier}&&index=${index}`}>
                    <button>Receipt</button>
                  </Link>
                  <form onSubmit={handleCheckout}>
                    <button type="submit">Checkout</button>
                  </form>
                </div>
              </div>
              <div className="GuestDashBoardRoom-account-title">
                <h2>Accounts</h2>
              </div>

              <div className="GuestDashBoardRoom-center-holder">
                <div className="GuestDashBoardRoom-content-table-section">
                  <main className="GT-container">
                    {userInfo &&
                    userInfo.position &&
                    (userInfo.position === "Receptionist") |
                      (userInfo.position === "Admin") ? (
                      <div className="GT-table-option">
                        <div className="GT-option-Button">
                          <div>
                            <img
                              src={play_btn}
                              alt="Play Button"
                              className={`${
                                isVisible ? "clicked-class" : "clicked-back"
                              }`}
                              onClick={handlePlayButtonClick}
                            />
                            <p>Room:</p>
                          </div>
                        </div>
                        {isVisible && (
                          <div className="GT-option-Body">
                            <section className="GT-table">
                              <div className="GT-Table-row table-row-header">
                                <p>Room</p>
                                <p>Date</p>
                                <p>Bill</p>
                                <p>Paid</p>
                                <p>Bal</p> {/* Added balance column */}
                              </div>
                              {data && data.length > 0 ? (
                                data
                                  .filter((room) => room.type === "Checkin")
                                  .map((item, index) => {
                                    const matchingPayment = payment?.find(
                                      (pay) =>
                                        pay.category === "Room" &&
                                        pay.comment.includes(`id:${item._id}`)
                                    );

                                    const balance = matchingPayment
                                      ? matchingPayment.bill -
                                        matchingPayment.paid
                                      : 0; // Calculate balance

                                    return matchingPayment ? (
                                      <div key={index} className="GT-Table-row">
                                        <p className="first-item">
                                          {item?.name || "N/A"}
                                        </p>
                                        <p>
                                          <DateFormatter
                                            isoDate={item?.createdAt || ""}
                                          />
                                        </p>
                                        <p>{matchingPayment?.bill || "0.0"}</p>
                                        <p>{matchingPayment?.paid || "0.0"}</p>
                                        <p>{balance || "0.0"}</p>{" "}
                                        {/* Display balance */}
                                      </div>
                                    ) : (
                                      <div key={index} className="GT-Table-row">
                                        <p className="first-item">
                                          {item?.name || "N/A"}
                                        </p>
                                        <p>
                                          <DateFormatter
                                            isoDate={item?.createdAt || ""}
                                          />
                                        </p>
                                        <p>{item?.price || "N/A"}</p>
                                        <p></p>
                                        <p></p>
                                      </div>
                                    );
                                  })
                              ) : (
                                <div
                                  style={{ color: "red", textAlign: "center" }}
                                ></div>
                              )}
                            </section>
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="GT-table-option">
                      <div className="GT-option-Button">
                        <div>
                          <img
                            src={play_btn}
                            alt="Play Button"
                            className={`${
                              isVisible1 ? "clicked-class" : "clicked-back"
                            }`}
                            onClick={() => setIsVisible1(!isVisible1)}
                          />
                          <p>Laundry:</p>
                        </div>
                      </div>
                      {isVisible1 && (
                        <div className="GT-option-Body">
                          <section className="GT-table">
                            <div className="GT-Table-row table-row-header">
                              <p>Items</p>
                              <p>Date</p>
                              <p>Bill</p>
                              <p>Paid</p>
                              <p>Bal</p>
                            </div>
                            {payment &&
                              payment
                                .filter((items) => items.checkedout === false)
                                .map((items) =>
                                  items.category === "Laundry" ? (
                                    <div
                                      key={items._id}
                                      className="GT-Table-row"
                                    >
                                      <p className="first-item">
                                        {items.comment}
                                      </p>
                                      <div className="GuestDashBoardRoom-mob-row">
                                        <p>Date:</p>
                                        <p>
                                          <DateFormatter
                                            isoDate={items?.updatedAt || ""}
                                          />
                                        </p>
                                      </div>
                                      <div className="GuestDashBoardRoom-mob-row">
                                        <p>Bill:</p>
                                        <p>{items.bill}</p>
                                      </div>
                                      <div className="GuestDashBoardRoom-mob-row">
                                        <p>Paid:</p>
                                        <p>{items.paid}</p>
                                      </div>
                                      <div className="GuestDashBoardRoom-mob-row">
                                        <p>Bal:</p>
                                        <p>{items.bill - items.paid}</p>{" "}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                )}
                          </section>
                        </div>
                      )}
                    </div>
                    <div className="GT-table-option">
                      <div className="GT-option-Button">
                        <div>
                          <img
                            src={play_btn}
                            alt="Play Button"
                            className={`${
                              isVisible2 ? "clicked-class" : "clicked-back"
                            }`}
                            onClick={() => setIsVisible2(!isVisible2)}
                          />
                          <p>Restaurant:</p>
                        </div>
                      </div>
                      {isVisible2 && (
                        <div className="GT-option-Body">
                          <section className="GT-table">
                            <div className="GT-Table-row table-row-header">
                              <p>Items</p>
                              <p>Date</p>
                              <p>Bill</p>
                              <p>Paid</p>
                              <p>Bal</p>
                            </div>
                            {payment &&
                              payment
                                .filter((items) => items.checkedout === false)
                                .map((items) =>
                                  items.category === "Restaurant" ? (
                                    <div
                                      key={items._id}
                                      className="GT-Table-row"
                                    >
                                      <p className="first-item">
                                        {items.comment}
                                      </p>
                                      <div className="GuestDashBoardRoom-mob-row">
                                        <p>Date:</p>
                                        <p>
                                          <DateFormatter
                                            isoDate={items?.updatedAt || ""}
                                          />
                                        </p>
                                      </div>
                                      <div className="GuestDashBoardRoom-mob-row">
                                        <p>Bill:</p>
                                        <p>{items.bill}</p>
                                      </div>
                                      <div className="GuestDashBoardRoom-mob-row">
                                        <p>Paid:</p>
                                        <p>{items.paid}</p>
                                      </div>
                                      <div className="GuestDashBoardRoom-mob-row">
                                        <p>Bal:</p>
                                        <p>{items.bill - items.paid}</p>{" "}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                )}
                          </section>
                        </div>
                      )}
                    </div>
                    <div className="GT-table-option">
                      <div className="GT-option-Button">
                        <div>
                          <img
                            src={play_btn}
                            alt="Play Button"
                            className={`${
                              isVisible3 ? "clicked-class" : "clicked-back"
                            }`}
                            onClick={() => setIsVisible3(!isVisible3)}
                          />
                          <p>Bar:</p>
                        </div>
                      </div>
                      {isVisible3 && (
                        <div className="GT-option-Body">
                          <section className="GT-table">
                            <div className="GT-Table-row table-row-header">
                              <p>Items</p>
                              <p>Date</p>
                              <p>Bill</p>
                              <p>Paid</p>
                              <p>Bal</p>
                            </div>
                            {payment &&
                              payment
                                .filter((items) => items.checkedout === false)
                                .map((items) =>
                                  items.category === "Bar" ? (
                                    <div
                                      key={items._id}
                                      className="GT-Table-row"
                                    >
                                      <p className="first-item">
                                        {items.comment}
                                      </p>
                                      <div className="GuestDashBoardRoom-mob-row">
                                        <p>Date:</p>
                                        <p>
                                          <DateFormatter
                                            isoDate={items?.updatedAt || ""}
                                          />
                                        </p>
                                      </div>
                                      <div className="GuestDashBoardRoom-mob-row">
                                        <p>Bill:</p>
                                        <p>{items.bill}</p>
                                      </div>
                                      <div className="GuestDashBoardRoom-mob-row">
                                        <p>Paid:</p>
                                        <p>{items.paid}</p>
                                      </div>
                                      <div className="GuestDashBoardRoom-mob-row">
                                        <p>Bal:</p>
                                        <p>{items.bill - items.paid}</p>{" "}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                )}
                          </section>
                        </div>
                      )}
                    </div>
                    <div className="GT-table-option">
                      <div className="GT-option-Button">
                        <div>
                          <img
                            src={play_btn}
                            alt="Play Button"
                            className={`${
                              isVisible4 ? "clicked-class" : "clicked-back"
                            }`}
                            onClick={() => setIsVisible4(!isVisible4)}
                          />
                          <p>Others:</p>
                        </div>
                      </div>
                      {isVisible4 && (
                        <div className="GT-option-Body">
                          <section className="GT-table">
                            <div className="GT-Table-row table-row-header">
                              <p>Items</p>
                              <p>Date</p>
                              <p>Bill</p>
                              <p>Paid</p>
                              <p>Bal</p>
                            </div>
                            {payment &&
                              payment
                                .filter((items) => items.checkedout === false)
                                .map((items) =>
                                  items.category === "Others" ? (
                                    <div
                                      key={items._id}
                                      className="GT-Table-row"
                                    >
                                      <p className="first-item">
                                        {items.comment}
                                      </p>
                                      <div className="GuestDashBoardRoom-mob-row">
                                        <p>Date:</p>
                                        <p>
                                          <DateFormatter
                                            isoDate={items?.updatedAt || ""}
                                          />
                                        </p>
                                      </div>
                                      <div className="GuestDashBoardRoom-mob-row">
                                        <p>Bill:</p>
                                        <p>{items.bill}</p>
                                      </div>
                                      <div className="GuestDashBoardRoom-mob-row">
                                        <p>Paid:</p>
                                        <p>{items.paid}</p>
                                      </div>
                                      <div className="GuestDashBoardRoom-mob-row">
                                        <p>Bal:</p>
                                        <p>{items.bill - items.paid}</p>{" "}
                                        {/* Display balance */}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                )}
                          </section>
                        </div>
                      )}
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestDashBoardRoom;
