// DateFormatter Component
export const DateFormatter = ({ isoDate }) => {
  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}.${month}.${year}`;
  };
  return <>{formatDate(isoDate)}</>;
};
