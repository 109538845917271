/** @format */

import apiClient from "../apiClient";
import { useMutation, useQuery } from "@tanstack/react-query";

// Add room
export const useAddRoomMutation = () => {
  return useMutation({
    mutationFn: async (room) => {
      const response = await apiClient.post("/room/add-room", room);
      return response.data;
    },
  });
};

// Fetch all rooms
export const useGetRoomsQuery = () => {
  return useQuery({
    queryKey: ["rooms"],
    queryFn: async () => {
      const response = await apiClient.get("rooms/rooms");
      return response.data;
    },
  });
};

// assign room hook
export const useAssignRoomMutation = () => {
  return useMutation({
    mutationFn: async (roomAssignment) => {
      const { roomId, ...payload } = roomAssignment;

      // Update the room using the PUT request
      const response = await apiClient.put(`rooms/room/${roomId}`, payload);

      return response.data;
    },
    onError: (error) => {
      console.error("Error assigning room:", error);
    },
  });
};

// assign room hook
export const useCheckoutRoomMutation = () => {
  return useMutation({
    mutationFn: async (roomAssignment) => {
      const { roomId, ...payload } = roomAssignment;

      // Update the room using the PUT request
      const response = await apiClient.put(
        `rooms/room/checkout/${roomId}`,
        payload
      );

      return response.data;
    },
    onError: (error) => {
      console.error("Error assigning room:", error);
    },
  });
};

export const useGetRoomDetailsQuery = (userUniqueId) => {
  return useQuery({
    queryKey: ["room", userUniqueId],
    queryFn: async () => {
      try {
        const response = await apiClient.get(`/rooms/room/${userUniqueId}`);
        return response.data;
      } catch (error) {
        throw new Error(
          error.response?.data?.message || "Failed to fetch guest details"
        );
      }
    },
    enabled: !!userUniqueId, // Ensure the query only runs if userUniqueId is provided
  });
};
// Fetch room by id
export const useGetRoomDetailQuery = (id) => {
  return useQuery({
    queryKey: ["room", id],
    queryFn: async () => {
      const response = await apiClient.get(`rooms/room/${id}`);
      return response.data;
    },
  });
};
