import React from "react";
import './CashierGuestDashboardRoomsList.css'

const CashierGuestDashboardRoomsList = ({date, CheckIn, Name, Amount}) => {
  return (
    <div className="CashierGuestDashboardRoomsList">
      <div className="CashierGuestDashboardRoomsList-name">
        <p>{Name}</p>
      </div>
      <div className="CashierGuestDashboardRoomsList-wide">
        <p>Date:</p> <p>{date}</p>
      </div>
      <div className="CashierGuestDashboardRoomsList-wide">
        <p>Check in:</p> <p>{CheckIn}</p>
      </div>
      <div className="CashierGuestDashboardRoomsList-wide">
        <p>Amount:</p> <p>{Amount}</p>
      </div>
    </div>
  );
};

export default CashierGuestDashboardRoomsList;
