import React from "react";
import "./ReservedRoomBtn.css";
import { Link } from "react-router-dom";
const ReservedRoomBtn = ({ amount, index, room, guest, roomId, date }) => {
  return (
    <Link
      to={`/ReservationInfo?room=${room}&&guest=${guest}&&roomId=${roomId}&&date=${date}`}
    >
      <button key={index} className="ReservedRoomBtn">
        <div>{amount}</div> <br />
        <span>{room}</span>
      </button>
    </Link>
  );
};

export default ReservedRoomBtn;
