import React, { useState } from "react";
import "./BartenderAccount.css";
import BartenderAccountList from "./BartenderAccountList/BartenderAccountList";
import BartenderHeader from "../BartenderHeader/BartenderHeader";
import BartenderProfile from "../BartenderProfile/BartenderProfile";

const BartenderAccountListData = [
  {
    Date: "03:23:2024",
    Category: "Restaurant",
    Description: "Rice, chicken, salad,water, fruit, cofee",
    Balance: "50,000.00",
  },
  {
    Date: "03:23:2024",
    Category: "Bar",
    Description: "Rice, chicken, salad",
    Balance: "10,000.00",
  },
  {
    Date: "03:23:2024",
    Category: "Restaurant",
    Description: "Rice, chicken, salad,water, fruit, cofee",
    Balance: "0.00",
  },
  {
    Date: "03:23:2024",
    Category: "Pool",
    Description: "Rice, chicken, salad,water, fruit, cofee",
    Balance: "10,000.00",
  },
  {
    Date: "03:23:2024",
    Category: "Bar",
    Description: "Rice, chicken, salad,water, fruit, cofee",
    Balance: "10,000.00",
  },
  {
    Date: "03:23:2024",
    Category: "Bar",
    Description: "Drinks and smoothy",
    Balance: "10,000.00",
  },
  {
    Date: "03:23:2024",
    Category: "Bar",
    Description: "Drinks and smoothy",
    Balance: "10,000.00",
  },
  {
    Date: "03:23:2024",
    Category: "Bar",
    Description: "Drinks and smoothy",
    Balance: "10,000.00",
  },
  {
    Date: "03:23:2024",
    Category: "Bar",
    Description: "Drinks and smoothy",
    Balance: "10,000.00",
  },
  {
    Date: "03:23:2024",
    Category: "Restaurant",
    Description: "Rice, chicken, salad,water, fruit, cofee",
    Balance: "50,000.00",
  },
  {
    Date: "03:23:2024",
    Category: "Bar",
    Description: "Rice, chicken, salad",
    Balance: "10,000.00",
  },
  {
    Date: "03:23:2024",
    Category: "Restaurant",
    Description: "Rice, chicken, salad,water, fruit, cofee",
    Balance: "0.00",
  },
  {
    Date: "03:23:2024",
    Category: "Pool",
    Description: "Rice, chicken, salad,water, fruit, cofee",
    Balance: "10,000.00",
  },
  {
    Date: "03:23:2024",
    Category: "Bar",
    Description: "Rice, chicken, salad,water, fruit, cofee",
    Balance: "10,000.00",
  },
  {
    Date: "03:23:2024",
    Category: "Bar",
    Description: "Drinks and smoothy",
    Balance: "10,000.00",
  },
  {
    Date: "03:23:2024",
    Category: "Bar",
    Description: "Drinks and smoothy",
    Balance: "10,000.00",
  },
  {
    Date: "03:23:2024",
    Category: "Bar",
    Description: "Drinks and smoothy",
    Balance: "10,000.00",
  },
  {
    Date: "03:23:2024",
    Category: "Bar",
    Description: "Drinks and smoothy",
    Balance: "10,000.00",
  },
];

const BartenderAccount = () => {
  const [barProfileShow, setBarProfileShow] = useState(false);
  return (
    <div className="BartenderAccount">
      <div className="BartenderAccount-profile">
        <BartenderProfile barProfileShow={true} />
      </div>
      <div className="BartenderAccount-space">
      <div className="BartenderAccount-mobile-header">
          <BartenderProfile
            barProfileShow={barProfileShow}
            setBarProfileShow={setBarProfileShow}
          />
          <BartenderHeader setBarProfileShow={setBarProfileShow} />
        </div>
        <div className="BartenderAccount-space-head">
          <p>Back to Homepage</p>
        </div>
        <div className="BartenderAccount-space-body">
          <div className="BartenderAccount-title-board">
            <p>Daily records</p>
            <div className="BartenderAccount-title-select">
              <select name="date" id="">
                <option value="Date">Date</option>
                <option value="Today">Today</option>
                <option value="Week">Week</option>
                <option value="Month">Month</option>
                <option value="Year">Year</option>
              </select>
              <select name="Category" id="">
                <option value="Today">Category</option>
                <option value="Bar">Bar</option>
                <option value="Restaurant">Restaurant</option>
                <option value="Laundry">Laundry</option>
                <option value="Room">Room</option>
                <option value="Others">Others</option>
              </select>
            </div>
          </div>
          <section className="BartenderAccount-board">
            <div className="BartenderAccount-center-board">
              <div className="BartenderAccount-table-head">
                <p>Date:</p>
                <p>Category</p>
                <p>Description</p>
                <p>Balance</p>
              </div>
              <div className="BartenderAccount-table-body">
                {BartenderAccountListData.map((item, index) => (
                  <BartenderAccountList
                    key={index}
                    Date={item.Date}
                    Category={item.Category}
                    Description={item.Description}
                    Balance={item.Balance}
                  />
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default BartenderAccount;
