import React from "react";
import copy from "../../../../assets/Copy.svg";
import { Link } from "react-router-dom";
import './CashierManageGuestList.css'

const CashierManageGuestList = ({Position, Name, Id}) => {
  return (
    <div className="CashierManageGuestList-table-row">
      <Link to='/CashierGuestDashboardRooms' className="CashierManageGuestListItem-link">{Name}</Link>
      <div className="CashierManageGuestListItem-id">
        <p>{Id}</p>{" "}
        <button className="CashierManageGuestListItem-id-btn">
          <img src={copy} alt="" />
        </button>
      </div>
      <p className="CashierManageGuestListItem-position">{Position}</p>
    </div>
  );
};

export default CashierManageGuestList;
