import React from 'react'
import './CashierAccountList.css';

const CashierAccountList = ({Category, Description, Balance, Date, key}) => {
  return (
    <div className="CashierAccountList" key={key}>
      <div className="CashierAccountList-item CashierAccountList-Date">
        <p>Date:</p> <p>{Date}</p>
      </div>
      <div className="CashierAccountList-Category">{Category}</div>
      <div className="CashierAccountList-item">
        <p>Description:</p> <p>{Description}</p>
      </div>
      <div className="CashierAccountList-item">
        <p>Balance:</p> <p>{Balance}</p>
      </div>
    </div>
  )
}

export default CashierAccountList