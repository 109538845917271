import React, { useRef, useState } from "react";
import { differenceInDays } from "date-fns";
import html2pdf from "html2pdf.js"; // Import the library
import hat from "../../assets/cap-brown.svg";
import logo from "../../assets/mob-logo.svg";
import { useGetGuestDetailsQuery } from "../../hooks/guest";
import { useFetchPaymentsByUserId } from "../../hooks/payment";
import { useGetRoomDetailsQuery } from "../../hooks/room";
import { DateFormatter } from "../../utils/DateFOrmatter";
import "./Receipt.css";

const Receipt = () => {
  const [identifier, setIdentifier] = useState(null);
  const [index, setIndex] = useState(null);
  const receiptRef = useRef(); // Create a reference for the receipt

  const { data: roomData } = useGetRoomDetailsQuery(identifier);
  const { data: guest } = useGetGuestDetailsQuery(index || "");
  const { data: paymentData } = useFetchPaymentsByUserId(index);

  // Parse URL params once
  React.useEffect(() => {
    const hash = window.location.hash;
    const queryString = hash.split("?")[1];
    const params = new URLSearchParams(queryString);
    const id = params.get("id");
    const index = params.get("index");
    const userUniqueId = params.get("userUniqueId");

    setIndex(index);
    setIdentifier(id || userUniqueId);
  }, []);

  // Calculate total cost, deposit, and balance
  const totalCost =
    roomData?.reduce((acc, room) => {
      if (room.type === "Checkin") {
        return acc + (Number(room.price) || 0);
      }
      return acc;
    }, 0) || 0;

  const totalDeposit =
    paymentData?.reduce((acc, payment) => {
      if (payment.category === "Room") {
        return acc + (payment.paid || 0);
      }
      return acc; // Add this return in case the condition is not met.
    }, 0) || 0;

  const totalBalance = totalCost - totalDeposit;

  // Manual print function triggered by the button
  const handlePrint = () => {
    if (receiptRef.current) {
      const opt = {
        margin: 1,
        filename: "ropajo.pdf",
        html2canvas: { scale: 3 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf().set(opt).from(receiptRef.current).save();
    }
  };

  return (
    <div>
      {/* Print Button */}
      <button
        onClick={handlePrint}
        style={{ float: "right", margin: "10px", color: "red" }}
      >
        Print Receipt
      </button>

      <div className="Receipt" ref={receiptRef}>
        <header className="Receipt-header">
          <div className="Receipt-container">
            <img src={logo} alt="Company Logo" />
            <div className="Receipt-head-container">
              <h1>INVOICE</h1>
            </div>
          </div>
        </header>

        <section className="Receipt-address">
          <div className="Receipt-address-container">
            <div className="Receipt-address-container-left">
              <p>Lobi Quarters, Makurdi Benue State.</p>
              <p>+2347055443322</p>
              <p>inquiries@ropajo.com</p>
              <p>www.ropajo.com</p>
            </div>
            <div className="Receipt-address-container-right">
              <p>Bill to:</p>
              <h2>{guest?.fullName}</h2>
              <div className="Receipt-address-container-right-profile">
                <p>{guest?.Nationality}</p>
                <p>{guest?.fullName}</p>
                <p>{guest?.address}</p>
                <p>{guest?.email}</p>
              </div>
            </div>
          </div>
        </section>

        <div className="Receipt-body">
          <section className="Receipt-table">
            <div className="Receipt-table-head">
              <p>DATE</p>
              <p>DESCRIPTION</p>
              <p>UNIT COST</p>
              <p>QUANTITY</p>
              <p>AMOUNT</p>
            </div>

            <div className="Receipt-table-body">
              {roomData && roomData.length > 0 ? (
                roomData
                  .filter((room) => room.type === "Checkin")
                  .map((item) => {
                    const matchingPayment = paymentData?.find(
                      (pay) =>
                        pay.category === "Room" &&
                        pay.comment.includes(`id:${item._id}`)
                    );

                    const amount = matchingPayment ? matchingPayment.paid : 0; // Use the paid amount as the amount

                    // Calculate the number of nights
                    const checkInDate = new Date(item.createdAt);
                    const today = new Date();
                    const nights = differenceInDays(today, checkInDate);

                    return (
                      <div key={item._id} className="Receipt-table-row">
                        <p>
                          <DateFormatter isoDate={item?.createdAt || ""} />
                        </p>
                        <p>{item?.name || "N/A"}</p>
                        <p>{item?.price?.toLocaleString() || "N/A"}</p>
                        <p>
                          {nights} {nights === 1 ? "Night" : "Nights"}
                        </p>
                        <p>{amount.toLocaleString() || "0.0"}</p>
                      </div>
                    );
                  })
              ) : (
                <p>No room data available.</p>
              )}
            </div>
          </section>
        </div>

        <footer className="Receipt-footer">
          <section className="Receipt-footer-left">
            <div className="Receipt-policy">
              <h2 className="Receipt-policy-header">
                RETURN POLICY, TERMS AND CONDITIONS
              </h2>
              <p className="Receipt-policy-text">
                The content of your return policy, terms, and conditions can be
                inserted here. Make sure it covers all the necessary information
                for the customer.
              </p>
            </div>
            <div className="Receipt-bank-detail">
              <p>ROPAJO HOTELS AND APARTMENTS</p>
              <p>0099887766</p>
              <p>ZENITH BANK PLC</p>
            </div>
          </section>

          <section className="Receipt-footer-right">
            <div className="Receipt-acc">
              <div className="Receipt-acc-board">
                <div className="Receipt-acc-title">
                  <p>TOTAL</p>
                  <p>DEPOSIT</p>
                  <p>BALANCE</p>
                </div>
                <div className="Receipt-acc-amount">
                  <p>{totalCost.toLocaleString()}</p>
                  <p>{totalDeposit.toLocaleString()}</p>
                  <p>{totalBalance.toLocaleString()}</p>
                </div>
              </div>
            </div>
            <div className="Receipt-hat-container">
              <img src={hat} alt="Logo Hat" />
            </div>
          </section>
        </footer>
      </div>
    </div>
  );
};

export default Receipt;
