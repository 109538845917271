import React from "react";
import "./CashierExpenses.css";
import GuestReservationHeader from "../../GuestReservationHeader/GuestReservationHeader";
import { Link } from "react-router-dom";

const CashierExpenses = () => {
  return (
    <div className="CashierExpenses">
      <div className="CashierExpenses-hue">
        <div className="CashierExpenses-head">
          <GuestReservationHeader />
        </div>
        <div className="CashierExpenses-body">
          <p>Expenses</p>
          <form action="" className="CashierExpenses-form">
            <div className="CashierExpenses-title">
              <p>Collected by: Martha Albert</p>
              <select name="Category" id="">
                <option value="Today">Category</option>
                <option value="Bar">Bar</option>
                <option value="Restaurant">Restaurant</option>
                <option value="Laundry">Laundry</option>
                <option value="Room">Room</option>
                <option value="Others">Others</option>
              </select>
            </div>

            <textarea name="" id="" cols={50} rows={4} placeholder="Description"></textarea>
            <label className="CashierExpenses-label">
              Amount:
              <input type="text" placeholder="" />
            </label>
            <Link to="/">
              <button type="submit">Submit</button>
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CashierExpenses;
