import React from "react";
import "./BillTo.css";
import { Link } from "react-router-dom";
import GuestReservationHeader from "../../GuestReservationHeader/GuestReservationHeader";

const BillTo = () => {
  return (
    <div className="BillTo">
      <div className="BillTo-hue">
        <div className="BillTo-head">
            <GuestReservationHeader/>
        </div>
        <div className="BillTo-body">
          <p>Bill To</p>

          <form action="" className="BillTo-form">
            <di className="BillTo-inputId">
            <label htmlFor="Bill to">Bill to: </label>
            <input type="text" placeholder="Guest ID" className="BillTo-guestId"/>
            </di>
            <div className="BillTo-title">
              <p>Collected by: Philip T.U</p>
              <select name="Category" id="">
                <option value="Today">Category</option>
                <option value="Bar">Bar</option>
                <option value="Restaurant">Restaurant</option>
                <option value="Laundry">Laundry</option>
                <option value="Room">Room</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <textarea name="" placeholder="Description" cols={50} rows={4} id=""></textarea>

            <label className="BillTo-label">
              Amount:
              <input type="text" placeholder="" />
            </label>
            <Link to="/">
              <button type="submit">Update</button>
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BillTo;
