import React, { useState } from "react";
import "./StaffInformation.css";
import minc_card from "../../../assets/Nimc_profile.jpg";
import staff_pic from "../../../assets/profile_picture.jpg";
import AdminProfile from "../AdminProfile/AdminProfile";
import AdminHeader from "../AdminHeader/AdminHeader";

const StaffInformation = () => {
  const [adminProfileShow, setAdminProfileShow] = useState(false);
  return (
    <div className="StaffInformation">
      <div className="StaffInformation-profile">
        <AdminProfile adminProfileShow={true} />
      </div>
      <div className="StaffInformation-space">
      <div className="StaffInformation-mobile-header">
          <AdminProfile
            adminProfileShow={adminProfileShow}
            setAdminProfileShow={setAdminProfileShow}
          />
          <AdminHeader setAdminProfileShow={setAdminProfileShow} />
        </div>
        <div className="StaffInformation-space-head">
          <p>Back to Homepage</p>
        </div>
        <div className="StaffInformation-space-body">
          <section className="StaffInformation-board">
            <div className="StaffInformation-center-board">
              <div className="StaffInformation-center-cont ContLeft">
                <div>
                  <h2>Kastina Malu</h2>
                  <p>{+99023311876}</p>
                </div>
                <div className="StaffInformation-info-section">
                  <div>
                    <div className="StaffInformation-mobile-salary">
                      <h2>Salary</h2>
                      <p>{"N56,000"}</p>
                    </div>
                  </div>
                  <h2>Staff ID: RX632JK90</h2>
                  <div className="StaffInformation-information">
                    <p>
                      <b>Address:</b>city 4 plus, Makurdi
                    </p>
                    <p>
                      <b>Email:</b>ajumatt@ropajo.org
                    </p>
                    <p>
                      <b>DOB:</b>25:10:1996
                    </p>
                    <p>
                      <b>Gender:</b>Female
                    </p>
                    <p>
                      <b>Qualification:</b> B.Sc Food Management Science
                    </p>
                    <p>
                      <b>Position:</b>Waiter
                    </p>
                  </div>
                </div>
                <div className="StaffInformation-cont-btn">
                  <button>Edit Salary</button>
                  <button>Stop Duties</button>
                </div>
                <div className="StaffInformation-mobile-nimc">
                  <img src={minc_card} alt="" />
                </div>
              </div>
              <div className="StaffInformation-center-cont ContRight">
                <div className="StaffInformation-contRight-up">
                  <div className="">
                    <h2>Salary</h2>
                    <p>{"N56,000"}</p>
                  </div>
                  <img src={staff_pic} alt="" />
                </div>
                <div className="StaffInformation-contRight-down">
                  <img src={minc_card} alt="" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default StaffInformation;
