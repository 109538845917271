import React, { useRef } from 'react'
import './DashboardRoomProfile.css'
// import { Link } from 'react-scroll'
import { Link } from 'react-router-dom'
import top_icon from '../../assets/top_arrow.svg'
import ropa_logo from '../../assets/mob-logo.svg'
// import profile_pic from '../../assets/profile_pic.png';




const DashboardRoomProfile = ({setProfileShow, profileShow}) => {

  const profile = useRef(null);

  const closeProfile =(e) => {
    if (e.target === profile.current) {
          setProfileShow(false);
    }
  }
  // const handleNavProfile = () => {
  //   setProfileShow(false);
  // };

  return (
    <div className={`dashboard-room-profile ${profileShow?'':'hide'}`}>
      <div className='dbp-navbar'>
        <div className="dbp-arrow-up">
          <img src={top_icon} alt="" ref={profile} onClick={closeProfile} />   
        </div>
        <div className="dbp-logo">
        <img src={ropa_logo} alt="" /> 
        </div>
        <div className='profile-cont'>
          <h4>Peter A.A</h4>
          <p>Welcome Chief</p>
        </div>
        <div className="dbp-nav-list">
          <ul>
            <button><Link to='/GuestAccessPage'>Home</Link></button>
            <button><Link to='/GuestAccount'>Account</Link></button>
            <button><Link>Signout</Link></button>
          </ul>
        </div>
        
      </div>
    </div>
  )
}

export default DashboardRoomProfile
