import React from "react";
import "./AdminAccountList.css";

const AdminAccountList = ({
  name,
  category,
  bill,
  paid,
  balance,
  date,
  index,
}) => {
  return (
    <div className="AdminAccounts-table-row" key={index}>
      <div className="AdminAccountList-staffName">
        <p>Name:</p>
        <p>{name}</p>
      </div>
      <div className="AdminAccountList-Category">
        <p>Category:</p>
        <p>{category}</p>
      </div>
      <div className="AdminAccountList-Bill">
        <p>Bill: </p>
        <p>{bill}</p>
      </div>
      <div>
        <p>Paid:</p>
        <p>{paid}</p>
      </div>
      <div>
        <p>Balance:</p>
        <p>{balance}</p>
      </div>
      <div>
        <p>Date</p>
        <p>{date}</p>
      </div>
    </div>
  );
};

export default AdminAccountList;
