/** @format */

import { useMutation, useQuery } from "@tanstack/react-query";
import apiClient from "../apiClient";

// fech all payments
export const useGetPaymentsQuery = () => {
  return useQuery({
    queryKey: ["payments"],
    queryFn: async () => (await apiClient.get("payment/all-payments")).data,
  });
};

// user payment
export const useFetchStaffPaymentsByUserId = (id) => {
  return useQuery({
    queryKey: ["payments", id],
    queryFn: async () => {
      const response = await apiClient.get(`payment/staff-payments/${id}`);

      return response.data;
    },
  });
};
// fetch payment
export const useGetPaymentDetailQuery = (userUniqueId) => {
  return useQuery({
    queryKey: ["payment", userUniqueId],
    queryFn: async () => (await apiClient.get(`payment/${userUniqueId}`)).data,
  });
};

// Hook for making a payment
export const useMakePaymentMutation = () => {
  return useMutation({
    mutationFn: async (paymentDetails) => {
      const response = await apiClient.post(
        `payment/make-payment`,
        paymentDetails
      );
      return response.data;
    },
    onError: (error) => {
      console.error("Error making payment:", error);
    },
  });
};
// Hook for updating the payment checkedout status
export const useCheckoutPaymentMutation = () => {
  return useMutation({
    mutationFn: async (paymentId) => {
      const response = await apiClient.put(
        `payment/update-checkout/${paymentId}`
      );
      return response.data;
    },
    onError: (error) => {
      console.error("Error updating checkout status:", error);
    },
  });
};

// user payment
export const useFetchPaymentsByUserId = (id) => {
  return useQuery({
    queryKey: ["payments", id],
    queryFn: async () => {
      const response = await apiClient.get(`payment/user-payments/${id}`);

      return response.data;
    },
  });
};
