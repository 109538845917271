/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useGetBookingsQuery } from "../../../hooks/book";
import { useGetRoomsQuery } from "../../../hooks/room"; // Adjust import path to your hook
import AvailableRooms from "../AvailableRooms/AvailableRooms";
import ReceptionHeader from "../ReceptionHeader/ReceptionHeader";
import ReceptionistDashboard from "../ReceptionistDashboard/ReceptionistDashboard";
import ReservedRoomBtn from "../ReservedRoomBtn/ReservedRoomBtn";
import "./StaffDashboard.css";

const StaffDashboard = () => {
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);

  const { data: bookings } = useGetBookingsQuery();
  // Fetch all rooms
  const { data: rooms, isLoading, isError } = useGetRoomsQuery();

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error loading rooms</p>;

  // Filter available rooms
  const availableRooms = rooms.filter((room) => room.isAvailable);

  const reservation = rooms.filter((room) => room.type === "Reservation");

  return (
    <>
      <div className="StaffDashboard">
        <div className="StaffDashboard-web">
          <div className="StaffDashboard-guest-section">
            <ReceptionistDashboard receptionProfileShow={true} />
          </div>

          <div className="StaffDashboard-content-section">
            <div className="StaffDashboard-mobile-header">
              <ReceptionistDashboard
                receptionProfileShow={receptionProfileShow}
                setReceptionProfileShow={setReceptionProfileShow}
              />
              <ReceptionHeader
                setReceptionProfileShow={setReceptionProfileShow}
              />
            </div>
            <div className="StaffDashboard-content-head">
              <Link>Back to Homepage</Link>
            </div>
            <div className="StaffDashboard-content-body">
              <p>Available Rooms</p>

              <div className="Staff-content-table-section">
                <div className="staff-room-btn-section">
                  {availableRooms.map((room, index) => (
                    <AvailableRooms
                      key={room._id} // Ensure unique key for each item
                      amount={`N${room.price.toLocaleString()}`}
                      room={room.name}
                      index={index}
                      roomId={room._id}
                    />
                  ))}
                </div>
                <div className="staff-apartments">
                  <p>Reserved Room</p>
                  <div className="staff-room-btn-section">
                    {reservation.map((room, index) => (
                      <ReservedRoomBtn
                        key={room._id}
                        amount={`N${room.price.toLocaleString()}`}
                        room={room.name}
                        index={index}
                        date={room.assignedDate}
                        guest={room.collectedFrom}
                        roomId={room._id}
                      />
                    ))}
                  </div>
                </div>

                <div className="Bookings-notification">
                  <Link
                    to="/OnlineBookingList"
                    className="button-StaffDashboard"
                  >
                    <span className="button-text">Bookings</span>
                    <span className="notification">
                      {bookings && bookings.length}
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="staff-option-btn-section">
              <Link to="/RegisterGuests" className="staff-dashboard-button">
                Register Guest
              </Link>
              <Link to="/ManageGuest" className="staff-dashboard-button">
                Manage Guests
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffDashboard;
