import React from "react";
import './ReceptionHeader.css'
import { Link, NavLink } from "react-router-dom";
import book_logo from '../../../assets/mob-logo.svg';
import mob_menu from '../../../assets/mob-menu.svg'

const ReceptionHeader = ({setReceptionProfileShow}) => {
  return (
    <div className="ReceptionHeader-header">
      <div className="ReceptionHeader-nav">
        <div className="ReceptionHeader-logo-holder">
          <NavLink to="/">
            <img src={book_logo} alt="" />
          </NavLink>
        </div>
        <div className="ReceptionHeader-nav-list">
          <ul>
            <li>
              <Link to="room" smooth={true} offset={0} duration={500}>
                Home
              </Link>
            </li>
            <li>
              <Link to="pool" smooth={true} offset={0} duration={500}>
                Account
              </Link>
            </li>
            <li>
              <Link to="laundry" smooth={true} offset={-760} duration={500}>
                History
              </Link>
            </li>
            <li>
              <Link to="gallery" smooth={true} offset={0} duration={500}>
                Signout
              </Link>
            </li>
          </ul>
        </div>
        <img
          src={mob_menu}
          alt=""
          className="ReceptionHeader-mob-menu"
          onClick={() => {
            setReceptionProfileShow(true);
          }}
        />
      </div>
    </div>
  );
};

export default ReceptionHeader;
