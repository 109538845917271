/** @format */

import React, { useContext, useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { Store } from "../../../Store";
import { NavLink } from "react-router-dom";
import top_icon from "../../../assets/top_arrow.svg";
import ropa_logo from "../../../assets/mob-logo.svg";
import receptionist2_pic from "../../../assets/receptionist_2.jpg";
import { convertToBase64 } from "../../../utils/convertImg";
import {
  useGetStaffDetailQuery,
  useUpdateStaffMutation,
} from "../../../hooks/staff";
import "./ReceptionistDashboard.css";

const ReceptionistDashboard = ({
  setReceptionProfileShow,
  receptionProfileShow,
}) => {
  const navigate = useNavigate();
  const reception = useRef(null);
  const {
    state: { userInfo },
    dispatch,
  } = useContext(Store);

  const { data: staff } = useGetStaffDetailQuery(userInfo?._id);
  const { mutateAsync: updateProfile, refetch } = useUpdateStaffMutation();

  // State to manage profile photo and whether it was updated
  const [photo, setProfilePhoto] = useState(receptionist2_pic);
  const [isPhotoUpdated, setIsPhotoUpdated] = useState(false);

  // Handle outside click to close profile
  const closeReception = (e) => {
    if (e.target === reception.current) {
      setReceptionProfileShow(false);
    }
  };

  // Ensure user is signed in
  useEffect(() => {
    if (!userInfo || !userInfo._id) {
      navigate("/SignIn");
    }
  }, [userInfo, navigate]);

  // Update profile photo if available from staff data
  useEffect(() => {
    if (staff?.photo) {
      setProfilePhoto(staff.photo);
    }
  }, [staff]);

  // Signout handler
  const signoutHandler = () => {
    dispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
    window.location.href = "/#/SignIn";
  };

  // Upload photo handler
  const uploadPhoto = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const base64 = await convertToBase64(file);
        setProfilePhoto(base64);
        setIsPhotoUpdated(true);
      } catch (error) {
        console.error("Error converting image:", error);
        toast.error("Failed to process the image.");
      }
    }
  };

  // Submit handler for updating the profile
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isPhotoUpdated) {
      toast.error("No changes to the photo");
      return;
    }

    try {
      const updatedData = { photo };

      const response = await updateProfile({ id: userInfo._id, updatedData });

      if (response) {
        toast.success("Profile photo updated successfully");
        refetch();
        setIsPhotoUpdated(false);
      } else {
        toast.error("Failed to update profile photo.");
      }
    } catch (error) {}
  };

  return (
    <div
      className={`ReceptionistDashboard ${receptionProfileShow ? " " : "hide"}`}
    >
      <div className="ReceptionistDashboard-arrow-up">
        <img src={top_icon} alt="" ref={reception} onClick={closeReception} />
      </div>
      <div className="ReceptionistDashboard-upper-sec">
        <div className="ReceptionistDashboard-logo">
          <Link to="/">
            <img src={ropa_logo} alt="" />
          </Link>
        </div>
        <div className="ReceptionistDashboard-profile">
          <div className="rdb-profile">
            <label htmlFor="upload-photo">
              <img src={photo} alt="Staff Profile" />
            </label>
            <form onSubmit={handleSubmit}>
              <input
                type="file"
                onChange={uploadPhoto}
                id="upload-photo"
                style={{ display: "none" }}
                accept="image/*"
              />
              {isPhotoUpdated && (
                <button
                  type="submit"
                  className="update-btn"
                  style={{ color: "green", padding: "8px" }}
                >
                  Update Photo
                </button>
              )}
            </form>
          </div>
          <h2>Receptionist II</h2>
          <p>{userInfo ? userInfo.fullName : ""}</p>
        </div>
      </div>
      <div className="ReceptionistDashboard-lower-sec">
        <ul>
          <NavLink
            to="/StaffDashboard"
            className={({ isActive }) => {
              return isActive ? "hover-rdb-btn" : "";
            }}
          >
            <li>Home</li>
          </NavLink>

          <NavLink
            to="/ReceptionAccount"
            className={({ isActive }) => {
              return isActive ? "hover-rdb-btn" : "";
            }}
          >
            <li>Payments</li>
          </NavLink>

          <NavLink
            to="/History"
            className={({ isActive }) => {
              return isActive ? "hover-rdb-btn" : "";
            }}
          >
            <li>History</li>
          </NavLink>
          <li>
            <button
              className="logout-btn ReceptionistDashboard-login"
              onClick={signoutHandler}
            >
              Signout
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ReceptionistDashboard;
