import React, { useState, useEffect } from "react";
import "./ReservationInfo.css";
import { Link, useNavigate } from "react-router-dom";

import { useAssignRoomMutation } from "../../../hooks/room";
import toast from "react-hot-toast";
import { useGetUserDetailsQuery } from "../../../hooks/guest";
import ReceptionistDashboard from "../ReceptionistDashboard/ReceptionistDashboard";
import ReceptionHeader from "../ReceptionHeader/ReceptionHeader";

const ReservationInfo = () => {
  const navigate = useNavigate();

  // DateFormatter Component
  const DateFormatter = ({ isoDate }) => {
    const formatDate = (isoDateString) => {
      const date = new Date(isoDateString);
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const year = date.getUTCFullYear();
      return `${day}.${month}.${year}`;
    };

    return <>{formatDate(isoDate)}</>;
  };

  const [receptionProfileShow, setReceptionProfileShow] = useState(false);
  const [room, setRoom] = useState("");
  const [guest, setGuest] = useState("");
  const [roomId, setRoomId] = useState("");
  // const [date, setDate] = useState("");

  useEffect(() => {
    const hash = window.location.hash;
    const queryString = hash.split("?")[1];
    const params = new URLSearchParams(queryString);

    const roomParam = params.get("room");
    const guestParam = params.get("guest");
    const roomIdParam = params.get("roomId");
    // const dateParam = params.get("date");

    setRoom(roomParam || "N/A");
    setGuest(guestParam || "N/A");
    // setDate(dateParam || "N/A");
    setRoomId(roomIdParam || "N/A");
  }, []);

  const { data: userData } = useGetUserDetailsQuery(guest);
  const { mutateAsync: assignRoom } = useAssignRoomMutation();

  const handleCheckIn = async (e) => {
    e.preventDefault();

    try {
      const assignmentData = {
        type: "Checkin",
        roomId,
      };

      await assignRoom(assignmentData); // Ensure this passes correctly
      toast.success("Guest Checked In");

      navigate(`/StaffDashboard`); // Navigate after success
    } catch (error) {
      console.error("Error during check-in:", error);
      toast.error("Error during check-in");
    }
  };

  // Handle Delete button (update room to be available and remove type)
  const handleDelete = async () => {
    try {
      const assignmentData = {
        type: "",
        isAvailable: true,
        roomId,
      };

      await assignRoom(assignmentData);
      toast.success("Room assignment deleted successfully");

      // Navigate back to the dashboard
      navigate(`/StaffDashboard`);
    } catch (error) {
      console.error("Error during room deletion:", error);
      toast.error("Error deleting room assignment");
    }
  };

  return (
    <div className="ReservationInfo">
      <div className="ReservationInfo-web">
        <div className="ReservationInfo-guest-section">
          <ReceptionistDashboard receptionProfileShow={true} />
        </div>

        <div className="ReservationInfo-content-section">
          <div className="ReservationInfo-mobile-header">
            <ReceptionistDashboard
              receptionProfileShow={receptionProfileShow}
              setReceptionProfileShow={setReceptionProfileShow}
            />
            <ReceptionHeader
              setReceptionProfileShow={setReceptionProfileShow}
            />
          </div>
          <div className="ReservationInfo-content-head">
            <Link to="/StaffDashboard">Back to Homepage</Link>
          </div>
          <div className="ReservationInfo-content-body">
            <div className="ReservationInfo-content-table-section">
              <div className="ReservationInfo-web-table-holder">
                <section className="ReservationInfo-section">
                  <form onSubmit={handleCheckIn}>
                    <div>
                      <h2>{userData?.fullName || "Guest Name"}</h2>
                      <p>+{userData?.phoneNumber || "Guest Phone"}</p>
                      <p>
                        <DateFormatter isoDate={new Date() || ""} />
                      </p>
                    </div>
                    <div>
                      <p>{room}</p>
                    </div>
                    <div className="ReservationInfo-buttons">
                      <button type="submit" style={{ width: "" }}>
                        CHECK IN
                      </button>
                      <button
                        type="button"
                        style={{ width: "200px" }}
                        onClick={handleDelete} // Handle delete action
                      >
                        DELETE
                      </button>
                    </div>
                  </form>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationInfo;
