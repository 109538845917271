import React, { useState } from "react";
import './GuestAccountTableRow.css'
import angle from '../../../assets/play-icon.svg'

const GuestAccountTableRow = ({index, Date, Category, Bill, Paid, Balance}) => {

    const [clicked, setClicked] = useState(undefined);


  return (
    <div className="GuestAccountTableRow" key={index}>
      <div className="GuestAccountTableRow-header">
        <img src={angle} alt="" className={`${clicked ?'clicked-GuestAccountTableRow-btn': 'clicked-back-GuestAccountTableRow'}`} onClick={ () => setClicked(!clicked)} />
        <p>{Category}:</p>
      </div>
      {clicked && (<div className="GuestAccountTableRow-body">
        <div className="GuestAccountTableRow-body-cell">
            <p>Date:</p>
            <p>{Date}</p>
        </div>
        <div className="GuestAccountTableRow-sub-category GuestAccountTableRow-body-cell">
            <p>Category:</p>
            <p>{Category}</p>
        </div>
        <div className="GuestAccountTableRow-body-cell">
            <p>Bill:</p>
            <p>{Bill}</p>
        </div>
        <div className="GuestAccountTableRow-body-cell">
            <p>Paid:</p>
            <p>{Paid}</p>
        </div>
        <div className="GuestAccountTableRow-body-cell">
            <p>Balance:</p>
            <p>{Balance}</p>
        </div>
      </div>)}
    </div>
  );
};

export default GuestAccountTableRow;
