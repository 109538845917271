import React, { useState } from "react";
import "./CashierDashboard.css";
import { Link } from "react-router-dom";
import CashierProfile from "../CashierProfile/CashierProfile";
import CashierHeader from "../CashierHeader/CashierHeader";

const CashierDashboard = () => {
  const [cashProfileShow, setCashProfileShow] = useState(false);
  return (
    <div className="CashierDashboard">
      <div className="CashierDashboard-profile">
        <CashierProfile cashProfileShow={true} />
      </div>
      <div className="CashierDashboard-space">
        <div className="CashierDashboard-mobile-header">
          <CashierProfile
            cashProfileShow={cashProfileShow}
            setCashProfileShow={setCashProfileShow}
          />
          <CashierHeader setCashProfileShow={setCashProfileShow} />
        </div>
        <div className="CashierDashboard-space-head">
          <p>Back to Homepage</p>
        </div>
        <div className="CashierDashboard-space-body">
          <div className="CashierDashboard-board-up">
            <p>Activities</p>
            <div className="CashierDashboard-board-up-container">
              <button>
                <Link to="/CashierExpenses">Expenses</Link>
              </button>
              <button>All Sales</button>
            </div>
          </div>
          <div className="CashierDashboard-board-down">
            <div className="CashierDashboard-board-down-container">
              <button>
                <Link to="/ManageGuest">Manage Guests</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashierDashboard;
