import React from "react";
import "./StaffList.css";
// import ReceptioistManageProfile from "../../ReceptioistManageProfile/ReceptioistManageProfile";
import AdminProfile from "../AdminProfile/AdminProfile";
import StaffListItem from "./StaffListItem/StaffListItem";
import AdminHeader from "../AdminHeader/AdminHeader";
import { useState } from "react";
import { useGetStaffsQuery } from "../../../hooks/staff";

// const staffListItems = [
//   {
//     Name: "Peter Patrick F.",
//     Id: "R1410x9232df7",
//     Position: "Receptionist",
//   },
//   {
//     Name: "Alice Matthew",
//     Id: "R1410x9232df7",
//     Position: "Bartender",
//   },
//   {
//     Name: "Moses Terna",
//     Id: "R1410x9232df7",
//     Position: "Waitress",
//   },
//   {
//     Name: "Hamsizan Katrinna",
//     Id: "R1410x9232df7",
//     Position: "Manager",
//   },
//   {
//     Name: "Fanen Torkura JP",
//     Id: "R1410x9232df7",
//     Position: "Security",
//   },
//   {
//     Name: "John Terhemba",
//     Id: "R1410x9232df7",
//     Position: "Tecnical",
//   },
//   {
//     Name: "Fanen Torkura JP",
//     Id: "R1410x9232df7",
//     Position: "Gardener",
//   },
//   {
//     Name: "Hamsizan Katrinna Malvin",
//     Id: "R1410x9232df7",
//     Position: "Cleaner",
//   },
//   {
//     Name: "Peter Patrick F.",
//     Id: "R1410x9232df7",
//     Position: "Receptionist",
//   },
//   {
//     Name: "Alice Matthew",
//     Id: "R1410x9232df7",
//     Position: "Receptionist",
//   },
//   {
//     Name: "Hamsizan Katrinna Malvin",
//     Id: "R1410x9232df7",
//     Position: "Cleaner",
//   },
// ];

const StaffList = () => {
  const { data: staffListItems } = useGetStaffsQuery();

  const [adminProfileShow, setAdminProfileShow] = useState(false);
  return (
    <div className="StaffList">
      <div className="StaffList-profile">
        <AdminProfile adminProfileShow={true} />
      </div>
      <div className="StaffList-space">
        <div className="StaffList-mobile-header">
          <AdminProfile
            adminProfileShow={adminProfileShow}
            setAdminProfileShow={setAdminProfileShow}
          />
          <AdminHeader setAdminProfileShow={setAdminProfileShow} />
        </div>
        <div className="StaffList-space-head">
          <p>Back to Homepage</p>
        </div>
        <div className="StaffList-space-body">
          <div className="StaffList-title-board">
            <h2>Staff List</h2>
            <input type="text" placeholder="Search Staff" />
          </div>
          <section className="StaffList-board">
            <div className="StaffList-center-board">
              <div className="StaffList-table-head">
                <p>Name</p>
                <p>Unique No.</p>
                <p>Position</p>
              </div>
              <div className="StaffList-table-body">
                {staffListItems?.map((items, index) => (
                  <StaffListItem
                    name={items.fullName}
                    id={items.staffUniqueId}
                    position={items.position}
                    index={index}
                  />
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default StaffList;
