/** @format */

import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useAssignRoomMutation } from "../../../hooks/room";
import { Store } from "../../../Store";
import GuestReservationHeader from "../../GuestReservationHeader/GuestReservationHeader";
import "./AssignRoom.css";

const AssignRoom = () => {
  const navigate = useNavigate();
  const {
    state: { userInfo },
  } = useContext(Store);

  const location = useLocation();

  const [totalAmount, setTotalAmount] = useState("");
  const [room, setRoom] = useState("");
  const [type, setType] = useState("");
  const [collectedFrom, setCollectedFrom] = useState("");
  const [roomId, setRoomId] = useState("");
  const [assignDate, setAssignDate] = useState(""); // New state for date

  const collectedBy = userInfo.uniqueId;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const amountParam = queryParams.get("amount");
    const roomParam = queryParams.get("room");
    const roomIdParam = queryParams.get("roomId");

    setTotalAmount(amountParam || "N/A");
    setRoom(roomParam || "N/A");
    setRoomId(roomIdParam || "");
  }, [location.search]);

  const { mutateAsync: assignRoom } = useAssignRoomMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!roomId) {
      toast.error("Room ID is missing");
      return;
    }

    if (!collectedFrom) {
      toast.error("Guest ID is required");
      return;
    }

    try {
      const assignmentData = {
        isAvailable: false,
        assignedDate: assignDate, // Use the selected date
        collectedFrom,
        collectedBy,
        bill: totalAmount,
        type,
        room,
        roomId,
      };

      await assignRoom(assignmentData);

      toast.success("Room assigned successfully");
      navigate(`/GuestDashBoardRoom?userUniqueId=${collectedFrom}`);
    } catch (error) {
      console.error("Error assigning room:", error);
      toast.error(error.message || "An unexpected error occurred");
    }
  };

  return (
    <div className="assignRoom">
      <div className="assignRoom-hue">
        <div className="head">
          <GuestReservationHeader />
        </div>
        <div className="assignRoom-mobile-head">
          {/* mobileheader for assign room */}
        </div>
        <form onSubmit={handleSubmit} className="assignRoom-body">
          <div className="assignRoom-body-top">
            <h3>Assign Room</h3>
            <div className="assignRoom-input-id">
            <label htmlFor="guest_id">Assign Room:</label>
            <input
              type="text"
              value={collectedFrom}
              onChange={(e) => setCollectedFrom(e.target.value)}
              name="guest_id"
              placeholder="Guest ID"
              />
              </div>
          </div>
          <div className="assignRoom-body-middle">
            <p>{room}</p>
            <div className="assignRoom-middle-checkbox">
              <label htmlFor="assign_room">
                <input
                  type="checkbox"
                  value="Checkin"
                  checked={type === "Checkin"}
                  onChange={() => setType("Checkin")}
                  name="Checkin"
                />
                Check in
              </label>
              <label htmlFor="assign_room">
                <input
                  type="checkbox"
                  value="Reservation"
                  checked={type === "Reservation"}
                  onChange={() => setType("Reservation")}
                  name="Reservation"
                />
                Reservation
              </label>
            </div>
            <div className="assign-date-container">
              <input
                type="date"
                name="assign_date"
                id="assign_date"
                className="assign_date"
                value={assignDate} // Bind the value to state
                onChange={(e) => setAssignDate(e.target.value)} // Update state on change
              />
            </div>
          </div>
          <div className="assignRoom-body-bottom">
            <button type="submit">Register</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AssignRoom;
