import React, { useContext, useEffect, useState } from "react";
import "./ReceptionAccount.css";
import { Link } from "react-router-dom";
import ReceptionistDashboard from "../ReceptionistDashboard/ReceptionistDashboard";
import ReceptionAccountTableRow from "../ReceptionAccountTableRow/ReceptionAccountTableRow";
import ReceptionHeader from "../ReceptionHeader/ReceptionHeader";
import { useFetchStaffPaymentsByUserId } from "../../../hooks/payment";
import { Store } from "../../../Store";
import { formatDistanceToNow } from "date-fns";

const ReceptionAccount = () => {
  const [id, setId] = useState();

  const {
    state: { userInfo },
  } = useContext(Store);

  const { data: receptionAccountTableRow } = useFetchStaffPaymentsByUserId(id);

  console.log(receptionAccountTableRow);

  const [receptionProfileShow, setReceptionProfileShow] = useState(false);

  useEffect(() => {
    if (userInfo) {
      setId(userInfo._id);
    }
  }, [userInfo]);

  return (
    <div className="ReceptionAccount">
      {/* websection starts here */}
      <div className="ReceptionAccount-web">
        <div className="ReceptionAccount-guest-section">
          <ReceptionistDashboard receptionProfileShow={true} />
        </div>

        <div className="ReceptionAccount-content-section">
          <div className="ReceptionAccount-mobile-header">
            <ReceptionistDashboard
              receptionProfileShow={receptionProfileShow}
              setReceptionProfileShow={setReceptionProfileShow}
            />
            <ReceptionHeader
              setReceptionProfileShow={setReceptionProfileShow}
            />
          </div>
          <div className="ReceptionAccount-content-head">
            <Link to="/">Back to Homepage</Link>
          </div>
          <div className="ReceptionAccount-content-body">
            <div className="ReceptionAccount-bar-holder">
              <div>
                <p>Account</p>
              </div>
              <div>
                <select name="Date" id="Date">
                  <option value="Date">Date</option>
                  <option value="Last Week">Month</option>
                  <option value="Year">Year</option>
                </select>
                <select name="Categoryate" id="Category">
                  <option value="Category">Category</option>
                  <option value="Room">Room</option>
                  <option value="Laundry">Laundry</option>
                  <option value="Bar">Bar</option>
                  <option value="Pool">Pool</option>
                  <option value="Restaurant">Restaurant</option>
                </select>
              </div>
            </div>

            <div className="ReceptionAccount-content-table-section">
              {/* table */}
              <div className="ReceptionAccount-web-table-holder">
                <table className="ReceptionAccount-web-table">
                  <thead>
                    <div className="header_div">
                      <span>Date</span>
                      <span>Category</span>
                      <span>Bill</span>
                      <span>Paid</span>
                      <span>Balance</span>
                    </div>
                  </thead>
                  <section>
                    {receptionAccountTableRow?.map((content) => {
                      const balance = content.bill - content.paid;
                      const formattedDate = formatDistanceToNow(
                        new Date(content.createdAt),
                        {
                          addSuffix: true,
                        }
                      );

                      return (
                        <ReceptionAccountTableRow
                          date={formattedDate}
                          category={content.category}
                          bill={content.bill}
                          paid={content.paid}
                          balance={`${balance}.00`}
                        />
                      );
                    })}
                  </section>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* websection ends here */}
    </div>
  );
};

export default ReceptionAccount;
