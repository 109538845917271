import React from "react";
import "./BartenderHeader.css";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import book_logo from "../../../assets/mob-logo.svg";
import mob_menu from "../../../assets/mob-menu.svg";

const BartenderHeader = ({ setBarProfileShow }) => {
  return (
    <div className="BartenderHeader-header">
      <div className="BartenderHeader-nav">
        <div className="BartenderHeader-logo-holder">
          <NavLink to="/">
            <img src={book_logo} alt="" />
          </NavLink>
        </div>
        <div className="BartenderHeader-nav-list">
          <ul>
            <li>
              <Link to="room" smooth={true} offset={0} duration={500}>
                Apartment
              </Link>
            </li>
            <li>
              <Link to="room" smooth={true} offset={0} duration={500}>
                Room
              </Link>
            </li>
            <li>
              <Link to="pool" smooth={true} offset={0} duration={500}>
                Pool
              </Link>
            </li>
            <li>
              <Link to="laundry" smooth={true} offset={-760} duration={500}>
                Laundry
              </Link>
            </li>
            <li>
              <Link to="gallery" smooth={true} offset={0} duration={500}>
                Gallery
              </Link>
            </li>
          </ul>
        </div>
        <img
          src={mob_menu}
          alt=""
          className="BartenderHeader-mob-menu"
          onClick={() => {
            setBarProfileShow(true);
          }}
        />
      </div>
    </div>
  );
};

export default BartenderHeader;
