/** @format */

import "./App.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { Toaster } from "react-hot-toast";
import { Route, HashRouter as Router, Routes } from "react-router-dom"; // Import BrowserRouter
import CashierAccount from "./Components/Cashier/CashierAccount/CashierAccount";
import CashierDashboard from "./Components/Cashier/CashierDashboard/CashierDashboard";
import CashierExpenses from "./Components/Cashier/CashierExpenses/CashierExpenses";
import CashierGuestDashboardRooms from "./Components/Cashier/CashierGuestDashboardRooms/CashierGuestDashboardRooms";
import CashierManageGuest from "./Components/Cashier/CashierManageGuest/CashierManageGuest";
import Dashboard from "./Components/Dashboard";
import DashboardComponents from "./Components/DashboardComponents/DashboardComponents";
import DashboardLaundry from "./Components/DashboardLaundry/DashboardLaundry";
import DashboardRoom from "./Components/DashboardRoom/DashboardRoom";
import GeneralAccount from "./Components/GeneralAccount/GeneralAccount";
import Booking from "./Components/Guest/Booking/Booking";
import GuestAccessPage from "./Components/Guest/GuestAccessPage/GuestAccessPage";
import GuestAccount from "./Components/Guest/GuestAccount/GuestAccount";
import GuestDashboardAccount from "./Components/GuestDashboardAccount/GuestDashboardAccount";
import GuestRoom from "./Components/GuestRoom/GuestRoom";
import LandingPage from "./Components/LandingPage/LandingPage";
import AdminAccounts from "./Components/Master-Admin/AdminAccounts/AdminAccounts";
import AdminDashboard from "./Components/Master-Admin/AdminDashboard/AdminDashboard";
import AdminLogin from "./Components/Master-Admin/AdminLogin/AdminLogin";
import CreateStaff from "./Components/Master-Admin/CreateStaff/CreateStaff";
import StaffInformation from "./Components/Master-Admin/StaffInformation/StaffInformation";
import StaffList from "./Components/Master-Admin/StaffList/StaffList";
import Receipt from "./Components/Receipt/Receipt";
import ReceptionistGuestPayment from "./Components/ReceptionistGuestPayment/ReceptionistGuestPayment";
import Register from "./Components/Register/Register";
import Reservations from "./Components/Reservations/Reservations";
import BartenderAccount from "./Components/Staff-BartenderAndServers/BartenderAccount/BartenderAccount";
import BartenderDashboard from "./Components/Staff-BartenderAndServers/BartenderDashboard/BartenderDashboard";
import BartenderManageGuest from "./Components/Staff-BartenderAndServers/BartenderManageGuest/BartenderManageGuest";
import BillTo from "./Components/Staff-BartenderAndServers/BillTo/BillTo";
import OpenSales from "./Components/Staff-BartenderAndServers/OpenSales/OpenSales";
import AssignRoom from "./Components/Staff-Receptionist/AssignRoom/AssignRoom";
import GuestDashBoardRoom from "./Components/Staff-Receptionist/GuestDashBoardRoom/GuestDashBoardRoom";
import ManageGuest from "./Components/Staff-Receptionist/ManageGuest/ManageGuest";
import OnlineBookingList from "./Components/Staff-Receptionist/OnlineBookingList/OnlineBookingList";
import ReceptionAccount from "./Components/Staff-Receptionist/ReceptionAccount/ReceptionAccount";
import RegisterGuests from "./Components/Staff-Receptionist/RegisterGuests/RegisterGuests";
import ReservationInfo from "./Components/Staff-Receptionist/ReservationInfo/ReservationInfo";
import SignIn from "./Components/Staff-Receptionist/SignIn/SignIn";
import UpdateGuestPayment from "./Components/Staff-Receptionist/UpdateGuestPayment/UpdateGuestPayment";
import { StoreProvider } from "./Store";

function App() {
  const [queryClient] = React.useState(() => new QueryClient());

  return (
    <Router>
      {" "}
      {/* Wrap your entire application inside Router */}
      <div className="App">
        <div>
          <Toaster
            position="top-right"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options
              className: "",
              duration: 5000,
              style: {
                background: "#363636",
                color: "#fff",
              },
            }}
          />
          <StoreProvider>
            <QueryClientProvider client={queryClient}>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/Booking" element={<Booking />} />{" "}
                {/* Define your Booking route */}
                <Route
                  path="/DashboardComponents"
                  element={<DashboardComponents />}
                />
                <Route path="/DashboardRoom" element={<DashboardRoom />} />
                <Route path="/Register" element={<Register />} />
                <Route
                  path="/DashboardLaundry"
                  element={<DashboardLaundry />}
                />
                <Route path="/GuestAccount" element={<GuestAccount />} />
                <Route path="/SignIn" element={<SignIn />} />
                <Route
                  path="/GuestDashboardAccount"
                  element={<GuestDashboardAccount />}
                />
                <Route path="/AssignRoom" element={<AssignRoom />} />
                <Route path="/RegisterGuests" element={<RegisterGuests />} />
                <Route path="/Reservations" element={<Reservations />} />
                <Route path="/GuestRoom" element={<GuestRoom />} />
                <Route
                  path="/GuestDashBoardRoom"
                  element={<GuestDashBoardRoom />}
                />
                <Route path="/GeneralAccount" element={<GeneralAccount />} />
                <Route path="/StaffDashboard" element={<Dashboard />} />
                <Route
                  path="/GuestAccessPage"
                  element={<GuestAccessPage />}
                />{" "}
                {/* Define your GuestAccessPage route */}
                <Route
                  path="/ReceptionistGuestPayment"
                  element={<ReceptionistGuestPayment />}
                />
                <Route
                  path="/UpdateGuestPayment"
                  element={<UpdateGuestPayment />}
                />
                <Route path="/ReservationInfo" element={<ReservationInfo />} />
                <Route path="/ManageGuest" element={<ManageGuest />} />
                <Route
                  path="/ReceptionAccount"
                  element={<ReceptionAccount />}
                />
                <Route
                  path="/OnlineBookingList"
                  element={<OnlineBookingList />}
                />
                <Route path="/CreateStaff" element={<CreateStaff />} />
                <Route path="/AdminLogin" element={<AdminLogin />} />
                <Route path="/AdminDashboard" element={<AdminDashboard />} />
                <Route path="/StaffList" element={<StaffList />} />
                <Route
                  path="/StaffInformation"
                  element={<StaffInformation />}
                />
                <Route path="/AdminAccounts" element={<AdminAccounts />} />
                <Route path="/OpenSales" element={<OpenSales />} />
                <Route path="/Receipt" element={<Receipt />} />
                <Route
                  path="/BartenderDashboard"
                  element={<BartenderDashboard />}
                />
                <Route path="/BillTo" element={<BillTo />} />
                <Route
                  path="/BartenderAccount"
                  element={<BartenderAccount />}
                />
                <Route
                  path="/BartenderManageGuest"
                  element={<BartenderManageGuest />}
                />
                <Route
                  path="/CashierDashboard"
                  element={<CashierDashboard />}
                />
                <Route path="/CashierAccount" element={<CashierAccount />} />
                <Route
                  path="/CashierManageGuest"
                  element={<CashierManageGuest />}
                />
                <Route
                  path="/CashierGuestDashboardRooms"
                  element={<CashierGuestDashboardRooms />}
                />
                <Route path="/CashierExpenses" element={<CashierExpenses />} />
              </Routes>
            </QueryClientProvider>
          </StoreProvider>
        </div>
      </div>
    </Router>
  );
}

// "RPJ336353"

export default App;
