import React from "react";
import "./BartenderAccountList.css";

const BartenderAccountList = ({
  Balance,
  key,
  Description,
  Date,
  Category,
}) => {
  return (
    <div className="BartenderAccountList" key={key}>
      <div className="BartenderAccountList-item BartenderAccountList-Date">
        <p>Date:</p> <p>{Date}</p>
      </div>
      <div className="BartenderAccountList-Category">{Category}</div>
      <div className="BartenderAccountList-item">
        <p>Description:</p> <p>{Description}</p>
      </div>
      <div className="BartenderAccountList-item">
        <p>Balance:</p> <p>{Balance}</p>
      </div>
    </div>
  );
};

export default BartenderAccountList;
