import React, { useContext } from "react";
import { Store } from "../Store";
import StaffDashboard from "./Staff-Receptionist/StaffDashboard/StaffDashboard";
import CashierDashboard from "./Cashier/CashierDashboard/CashierDashboard";
import BartenderDashboard from "./Staff-BartenderAndServers/BartenderDashboard/BartenderDashboard";
import AdminDashboard from "./Master-Admin/AdminDashboard/AdminDashboard";

const Dashboard = () => {
  const {
    state: { userInfo },
  } = useContext(Store);

  // Ensure userInfo is defined before trying to access its properties
  if (!userInfo) {
    return <div>Loading...</div>; // Or any fallback UI
  }

  return (
    <>
      {userInfo.position === "Receptionist" ? (
        <StaffDashboard />
      ) : userInfo.position === "Admin" ? (
        <AdminDashboard />
      ) : userInfo.position === "Bartender" ||
        userInfo.position === "Waiter" ? ( // Fixed this condition
        <BartenderDashboard />
      ) : userInfo.position === "Cashier" ? (
        <CashierDashboard />
      ) : (
        <div>Unauthorized User</div> // Handle cases where position is not recognized
      )}
    </>
  );
};

export default Dashboard;
