import React, { useContext, useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { Link, NavLink } from "react-router-dom";
import { Store } from "../../../Store";
import top_icon from "../../../assets/top_arrow.svg";
import ropa_logo from "../../../assets/mob-logo.svg";
import admin_pic from "../../../assets/receptionist_1.jpg";
import { convertToBase64 } from "../../../utils/convertImg";
import {
  useGetStaffDetailQuery,
  useUpdateStaffMutation,
} from "../../../hooks/staff";
import "./BartenderProfile.css";

const BartenderProfile = ({ barProfileShow, setBarProfileShow }) => {
  const barProfile = useRef(null);
  const {
    state: { userInfo },
    dispatch,
  } = useContext(Store);

  const { data: staff } = useGetStaffDetailQuery(userInfo?._id);
  const { mutateAsync: updateProfile, refetch } = useUpdateStaffMutation();

  const [photo, setProfilePhoto] = useState(admin_pic);
  const [isPhotoUpdated, setIsPhotoUpdated] = useState(false);

  // Close the profile section when clicking outside
  const closeProfile = () => {
    setBarProfileShow(false);
  };

  useEffect(() => {
    if (staff?.photo) {
      setProfilePhoto(staff.photo);
    }
  }, [staff]);

  const signoutHandler = () => {
    dispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
    window.location.href = "/#/SignIn";
  };

  const uploadPhoto = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const base64 = await convertToBase64(file);
        setProfilePhoto(base64);
        setIsPhotoUpdated(true);
      } catch (error) {
        console.error("Error converting image:", error);
        toast.error("Failed to process the image.");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isPhotoUpdated) {
      toast.error("No changes to the photo");
      return;
    }

    try {
      const updatedData = { photo };

      const response = await updateProfile({ id: userInfo._id, updatedData });

      if (response) {
        toast.success("Profile photo updated successfully");
        refetch();
        setIsPhotoUpdated(false);
      } else {
        toast.error("Failed to update profile photo.");
      }
    } catch (error) {}
  };

  return (
    <div className={`BartenderProfile ${barProfileShow ? "" : "hide"}`}>
      <div className="BartenderProfile-arrow-up">
        <img
          src={top_icon}
          alt="top icon"
          ref={barProfile}
          onClick={closeProfile}
        />
      </div>
      <div className="BartenderProfile-upper-sec">
        <div className="BartenderProfile-logo">
          <Link to="/">
            <img src={ropa_logo} alt="Logo" />
          </Link>
        </div>
        <div className="BartenderProfile-profile">
          <label htmlFor="upload-photo">
            <img src={photo} alt="Staff Profile" />
          </label>
          <form onSubmit={handleSubmit}>
            <input
              type="file"
              onChange={uploadPhoto}
              id="upload-photo"
              style={{ display: "none" }}
              accept="image/*"
            />
            {isPhotoUpdated && (
              <button
                type="submit"
                className="update-btn"
                style={{ color: "green", padding: "8px" }}
              >
                Update Photo
              </button>
            )}
          </form>
          <h2>{userInfo?.fullName || "Bartender"}</h2>
          <p>Good afternoon</p>
        </div>
      </div>
      <div className="BartenderProfile-lower-sec">
        <ul>
          <NavLink
            to="/StaffDashboard"
            className={({ isActive }) => {
              return isActive ? "hover-gdb-btn" : "";
            }}
          >
            <li>Home</li>
          </NavLink>
          <NavLink
            to="/BartenderAccount"
            className={({ isActive }) => {
              return isActive ? "hover-gdb-btn" : "";
            }}
          >
            <li>Payment</li>
          </NavLink>
          <button
            className="logout-btn ReceptionistDashboard-login"
            onClick={signoutHandler}
          >
            Signout
          </button>
        </ul>
      </div>
    </div>
  );
};

export default BartenderProfile;
